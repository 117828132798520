.loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}

.loading_icon {
    width: 6.4vw;
    height: 6.4vw;
    background: conic-gradient(from 90deg at 50% 50%, #fff 0deg, currentcolor 1turn);
    border-radius: 50%;
    -webkit-mask: radial-gradient(transparent calc(50% - 0.5px), #fff 50%);
    mask: radial-gradient(transparent calc(50% - 0.5px), #fff 50%);
    background: conic-gradient(from 90deg at 50% 50%, rgba(0, 82, 217, 0) 0deg, rgb(0, 82, 217) 360deg);
    animation: 0.8s linear 0s infinite normal none running t-spin;
}

@-webkit-keyframes t-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}
@keyframes t-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}
